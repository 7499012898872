(function () {
  'use strict';

  var ErrorMessage = function () {
    return {
      template: [
        '<div class="error-messages" ng-messages="options.formControl.$error" ng-if="options.validation.errorExistsAndShouldBeVisible">',
        '<div class="error-message" ng-message="{{::name}}" ng-repeat="(name, message) in ::options.validation.messages">',
        '{{message(options.formControl.$viewValue, options.formControl.$modelValue, this)}}',
        '</div>',
        '</div>'
      ].join(''),
      scope: {
        options: '=messages'
      }
    };
  };

  angular.module('musaned.common.errorMessages')
    .directive('errorMessage', ErrorMessage);
})();
