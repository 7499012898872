(function () {
  'use strict';

  var saudiLocation = function ($q, $window) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModelCtrl) {
        var geocoder = new $window.google.maps.Geocoder();

        ngModelCtrl.$asyncValidators.saudiLocation = function (modelVal, viewVal) {
          var deferred = $q.defer();

          if (ngModelCtrl.$isEmpty(modelVal)) {
            return $q.when();
          }

          var latLng = new $window.google.maps.LatLng(modelVal);

          geocoder.geocode({ latLng: latLng }, function (results, status) {
            if (status != google.maps.GeocoderStatus.OK) {
              return deferred.reject();
            }

            return deferred.resolve();
          });

          return deferred.promise;
        };
      }
    };
  };

  angular.module('musaned.common.forms')
    .directive('tmkSaudiLocation', saudiLocation);
})();
