(function () {
  'use strict';

  var mobile = function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, element, attrs, ctrl) {
        ctrl.$validators.mobile = function (modelValue, viewValue) {
          return /^05\d{8}$/.test(viewValue);
        };
      }
    }
  };

  angular.module('musaned.common.forms')
    .directive('tmkMobile', mobile);
})();
