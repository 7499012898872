(function () {
  'use strict';

  var password = function (Validators) {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$validators.password = function (modelValue, viewValue) {
          if (ngModelCtrl.$isEmpty(viewValue)) {
            return true;
          }

          return Validators.passwordComplexity(attrs.tmkPassword)(viewValue);
        };
      }
    };
  };

  angular.module('musaned.common.forms')
    .directive('tmkPassword', password);
})();
