(function () {
  'use strict';

  var confirmed = function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$validators.confirmed = function (modelValue, viewValue) {
          return viewValue == scope[attrs.tmkConfirmed];
        };

        scope.$watch(attrs.tmkConfirmed, function() {
          ngModelCtrl.$validate();
        });
      }
    };
  };

  angular.module('musaned.common.forms')
    .directive('tmkConfirmed', confirmed);
})();
