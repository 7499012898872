(function () {
  'use strict';

  var Base64 = function () {
    var Base64 = {};

    Base64.decode = function (str) {
      var output = str.replace('-', '+').replace('_', '/');

      switch (output.length % 4) {
        case 0:
          break;
        case 1:
          throw 'Illegal base64url string!';
        case 2:
          output += '==';
          break;
        case 3:
          output += '=';
          break;
      }

      return window.atob(output);
    };

    return Base64;
  };

  angular.module('musaned.common.base64')
    .service('Base64', Base64);
})();
