(function () {
  'use strict';

  var arabicNumbers = function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        var arabicDigits = function (value) {
          if (value == undefined) value = '';
          var arabicDigits = value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
            return d.charCodeAt(0) - 1632;
          }).replace(/(?![0-9]).+/g, function () {
            return '';
          });

          if (arabicDigits !== value) {
            ctrl.$setViewValue(arabicDigits);
            ctrl.$render();
          }
          return arabicDigits;
        };
        ctrl.$parsers.push(arabicDigits);
      }
    };
  };

  angular.module('musaned.common.forms')
    .directive('tmkArabicNumbers', arabicNumbers);
})();
