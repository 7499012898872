(function () {
  'use strict';

  var pdfViewer = function (blockUI) {
    var canvas = null;

    return {
      templateUrl: '/partials/common/pdf-viewer/pdf-viewer.html',
      restrict: 'E',
      transclude: true,
      scope: {
        loadProgress: '&',
        fileUrl: '=src'
      },
      controller: ['$scope', 'locker', 'blockUI', function ($scope, locker) {
        $scope.scale = 1.0;
        $scope.pageNum = 1;
        $scope.pdfDoc = null;
        $scope.error = null;
        $scope.loadPDF = function (path) {
          var block = blockUI.instances.get('viewer_container');
          block.start();

          var token = locker.get('AUTH-TOKEN');
          var docInitParams = {
            url: path,
            httpHeaders: {"X-AUTH-TOKEN": token},
            withCredentials: true
          };
          PDFJS.getDocument(docInitParams, null, null, $scope.documentProgress).then(function (_pdfDoc) {
            $scope.pdfDoc = _pdfDoc;
            $scope.renderPage($scope.pageNum, function (success) {
              if ($scope.loadProgress) {
                $scope.loadProgress({state: "finished", loaded: 0, total: 0});
              }
              block.stop();
            });
          }, function (message, exception) {
            if ($scope.loadProgress) {
              $scope.loadProgress({state: "error", loaded: 0, total: 0});
            }
            $scope.error = true;
            sweetAlert({
              type: 'error',
              title: 'error',
              text: 'حدث خطا اثناء تحميل الملف'
            });          });
        };

        $scope.renderPage = function (num, callback) {
          $scope.pdfDoc.getPage(num).then(function (page) {
            var viewport = page.getViewport($scope.scale);
            var ctx = canvas.getContext('2d');

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            page.render({canvasContext: ctx, viewport: viewport}).promise.then(
              function () {
                if (callback) {
                  callback(true);
                }
                $scope.$apply(function () {
                  $scope.onPageLoad({page: $scope.pageNum, total: $scope.pdfDoc.numPages});
                });
              },
              function () {
                if (callback) {
                  callback(false);
                }
              }
            );
          });
        };
      }],
      link: function (scope, elem, attr) {
        canvas = elem.find('canvas')[0];

        scope.onPageLoad = function (curDoc) {
          scope.currentPage = curDoc.page;
          scope.totalPages = curDoc.total;
          scope.curPage = scope.currentPage;
        };

        scope.gotoNext = function () {
          if (scope.pageNum < scope.pdfDoc.numPages) {
            scope.pageNum++;
            scope.renderPage(scope.pageNum);
          }
        };

        scope.gotoPrev = function () {
          if (scope.pageNum > 1) {
            scope.pageNum--;
            scope.renderPage(scope.pageNum);
          }
        };

        scope.gotoPage = function (page) {
          page = parseInt(page);
          if (page >= 1 && page <= scope.pdfDoc.numPages) {
            scope.pageNum = page;
            scope.renderPage(scope.pageNum);
          }
        };

        scope.pageNum = 1;
        scope.loadPDF(attr.path + scope.fileUrl);
      }
    };
  };

  angular.module('musaned.common.pdf')
    .directive('pdfViewer', pdfViewer);
})();
