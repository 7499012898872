(function () {
  'use strict';

  var idNumber = function (Validators) {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$validators.idNumber = function (modelValue, viewValue) {
          if (ngModelCtrl.$isEmpty(viewValue)) {
            return true;
          }

          if (attrs.tmkIdNumber != '') {
            if (attrs.tmkIdNumber != '1' && attrs.tmkIdNumber != '2') {
              return false;
            }

            if ((attrs.tmkIdNumber == '1' && viewValue[0] != '1') || (attrs.tmkIdNumber == '2' && viewValue[0] != '2')) {
              return false;
            }
          }

          return Validators.idNumber(viewValue);
        };
      }
    };
  };

  angular.module('musaned.common.forms')
    .directive('tmkIdNumber', idNumber);
})();
