(function () {
  'use strict';

  var MediaPreviewCtrl = function ($scope, $modal, locker) {
    var vm = this;

    vm.preview = function (path, id, mimetype) {
      var template;
      if (mimetype == 'application/pdf') {
        template = '<pdf-viewer path="' + path + '" ng-attr-src="' + id + '"></pdf-viewer>';
      } else {
        template = '<div id="result"></div><img class="img-responsive" ng-class="{rotate90:image == 90, rotate180:image == 180, rotate270:image == 270}" src="' + path + id + '?X-AUTH-TOKEN=' + locker.get('AUTH-TOKEN') + '" onerror="this.src=\'/img/unavailable.png\'; $(\'#result\').text(\'حدث خطا اثناء تحميل الملف\');">';
      }

      template = '<div class="modal-body">\
      '+ template +'\
      </div>\
      <div class="modal-footer preview">';

      if (mimetype != 'application/pdf') {
        template += '<button class="btn btn-success" name="rotate" type="button" ng-click="rotate(90)"><i class="fa fa-repeat"></i></button>\
      <button class="btn btn-success" name="rotate" type="button" ng-click="rotate(-90)"><i class="fa fa-undo"></i></button>';
      }

      template += '<button class="btn btn-warning" name="close" type="button" ng-click="$close()">إغلاق</button>\
      </div>';

      $modal.open({
        animation: true,
        template: template,
        size: 'lg',
        controller: function ($scope) {
          $scope.image = 0;
          $scope.rotate = function (rotate) {
            $scope.image += rotate;

            if(rotate == -90 && $scope.image == -90) {
              $scope.image = 270;
            }

            if(rotate == 90 && $scope.image == 360 ) {
              $scope.image = 0;
            }
          };
        }
      });
    };
  };

  angular.module('musaned.common.mediaPreview')
    .controller('MediaPreviewCtrl', MediaPreviewCtrl);
})();
