(function () {
  'use strict';

  var Validators = function ($http) {
    var Validators = {};

    Validators.idNumber = function (str) {
      var value = str.toString().split('').map(function (v) {
        return parseInt(v);
      });

      if (value.length != 10) return false;

      var nums = value.map(function (v, i) {
        if (i % 2 === 0) {
          var s = v * 2;
          return s % 10 + Math.floor(s / 10);
        }
        return v;
      });

      return nums.reduce(function (a, b) {
          return a + b;
        }, 0) % 10 === 0;
    };

    Validators.uniqueUsername = function ($viewValue, $modelValue, scope) {
      scope.options.templateOptions.loading = true;

      var res = false;
      $http.post('/api/auth/register/username', {username: $viewValue})
        .then(function () {
          res = true;
        }, function () {
          res = false;
        })
        .finally(function () {
          scope.options.templateOptions.loading = false;
        });

      return res;
    };

    Validators.passwordComplexity = function (complexity) {
      var passwordComplexities = {
        user: {
          length: 8,
          contains: ['uppercase', 'lowercase', 'number']
        },
        other: {
          length: 12,
          contains: ['uppercase', 'lowercase', 'number']
        }
      };

      var regexes = {
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        number: /[0-9]/,
        symbol: /[!@#$%^&*\(\)-_\|\\/]/
      };

      return function (value) {
        var c = passwordComplexities[complexity];

        if (value.length < c.length) return false;

        for (var i = 0; i < c.contains.length; i++) {
          if (!regexes[c.contains[i]].test(value)) return false;
        }

        return true;
      };
    };

    return Validators;
  };

  angular.module('musaned.common.validators')
    .service('Validators', Validators);
})();
