(function () {
  'use strict';

  var Locale = function ($rootScope, $translate) {
    var Locale = {};

    Locale.currentLang = $translate.use();

    zE(function() {
      zE.setLocale(Locale.currentLang);
    });

    document.documentElement.setAttribute('lang', Locale.currentLang);

    $rootScope.$on('$translateChangeSuccess', function (event, data) {
      document.documentElement.setAttribute('lang', data.language);

      $rootScope.lang = data.language;

      zE(function() {
        zE.setLocale(data.language);
      });
    });

    $rootScope.lang = Locale.currentLang;

    Locale.setLang = function (langKey) {
      Locale.currentLang = langKey;

      $rootScope.lang = langKey;

      $translate.use(langKey);
    };

    return Locale;
  };

  angular.module('musaned.common.locale')
    .service('Locale', Locale);
})();
