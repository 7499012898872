(function () {
  'use strict';

  var LocaleCtrl = function (Locale) {
    var vm = this;

    vm.setLang = function (langKey) {
      Locale.setLang(langKey);
    };
  };

  angular.module('musaned.common.locale')
    .controller('LocaleCtrl', LocaleCtrl);
})();
